import React from "react"

import { graphql } from "gatsby"
// Components
import Layout from "../components/Global/Layout"
import HeaderImage from "../components/Shared/HeaderImage"

import WhatsOnContainer from "../components/WhatsOn/WhatsOnContainer"

import { venueForPage } from "../helpers/venueForPage"
import SEOSettings from "../components/Shared/SEOSettings"

const TemplateWhatsOn = (props) => {
    // console.log("Data", props)
    const venue = venueForPage({ page: props.data.page, menus: props.data.menus })

    const filters = {
        audience_type: props.data.audience_type,
        event_type: props.data.event_type,
        event_theme: props.data.event_theme,
        venue: props.data.venue,
    }

    return (
        <Layout venue={venue} currentLocation={props.path} title={props.data.page.title}>
            <SEOSettings entity={props.data.page} />
            <WhatsOnContainer {...props} filters={filters} venueId={venue.venueId} />
        </Layout>
    )
}

export const pageQuery = graphql`
    query WhatsOnQuery($databaseId: Int!, $venueId: String) {
        menus: allWpMenu {
            results: nodes {
                ...PageMenusNode
            }
        }
        page: wpPage(databaseId: { eq: $databaseId }) {
            ...PageContent
            seo {
                metaDesc
                title
                opengraphTitle
                opengraphDescription
                metaKeywords
                metaRobotsNofollow
                metaRobotsNoindex
                opengraphImage {
                  sourceUrl
                }
            }
        
        }

        allEvents: allWpEvent(limit: 8) {
            results: nodes {
                id
                title
                link
                offsite_event_warning {
                    display: displayEventLocationWarning
                    description: warningMessage
                    title: warningTitle
                    hideVenueName
                }
                event_fields {
                    eventDates {
                        startDate
                        endDate
                    }
                }
                contentType {
                    node {
                        name
                    }
                }
                image: hero_fields {
                    image {
                        sourceUrl
                        title
                    }
                }
                venueFields {
                    venue {
                        ... on WpVenue {
                            id
                            title
                        }
                    }
                }
            }
            pageInfo {
                currentPage
                totalCount
                hasNextPage
            }
        }

        events: allWpEvent(filter: { venueId: { eq: $venueId } }, limit: 8) {
            results: nodes {
                id
                title
                link
                offsite_event_warning {
                    display: displayEventLocationWarning
                    description: warningMessage
                    title: warningTitle
                    hideVenueName
                }
                event_fields {
                    eventDates {
                        startDate
                        endDate
                    }
                }
                contentType {
                    node {
                        name
                    }
                }
                image: hero_fields {
                    image {
                        sourceUrl
                        title
                    }
                }
                venueFields {
                    venue {
                        ... on WpVenue {
                            id
                            title
                        }
                    }
                }
            }
            pageInfo {
                currentPage
                totalCount
                hasNextPage
            }
        }
        venue: allWpVenue {
            options: edges {
                filter: node {
                    id: databaseId
                    title
                    __typename
                }
            }
        }
        event_type: allWpEventType {
            options: edges {
                filter: node {
                    id: databaseId
                    title
                    __typename
                }
            }
        }
        event_theme: allWpEventTheme {
            options: edges {
                filter: node {
                    id: databaseId
                    title
                    slug
                    __typename
                }
            }
        }
        audience_type: allWpAudience {
            options: edges {
                filter: node {
                    id: databaseId
                    title
                    __typename
                }
            }
        }

        #wpgraphql {
        #...PageMenus
        #page(id: $databaseId, idType: DATABASE_ID) {
        #    ...PageContent
        #}

        #events(first: 8, where: { venue: $venueId, upcoming: true }) {
        #    results: nodes {
        #        id
        #        title
        #        link
        #        offsite_event_warning {
        #            display: displayEventLocationWarning
        #            description: warningMessage
        #            title: warningTitle
        #            hideVenueName
        #        }
        #        event_fields {
        #            eventDates {
        #                startDate
        #                endDate
        #            }
        #        }
        #        contentType {
        #            node {
        #                name
        #            }
        #        }
        #        image: hero_fields {
        #            image {
        #                sourceUrl
        #                title
        #            }
        #        }
        #        venue {
        #            venue {
        #                ... on WpVenue {
        #                    id
        #                    title
        #                }
        #            }
        #        }
        #    }
        #    pageInfo {
        #        hasNextPage
        #        endCursor
        #    }
        #}
        #venue: venues {
        #    options: edges {
        #        filter: node {
        #            id: venueId
        #            title
        #            __typename
        #        }
        #    }
        #}
        #event_type: eventTypes {
        #    options: edges {
        #        filter: node {
        #            id: eventTypeId
        #            title
        #            __typename
        #        }
        #    }
        #}
        #audience_type: audiences {
        #    options: edges {
        #        filter: node {
        #            id: audienceId
        #            title
        #            __typename
        #        }
        #    }
        #}
        #}
    }
`

export default TemplateWhatsOn
