import React from "react"

// Styles
import * as styles from "./HeaderImage.module.scss"

// Components
import PlaceholderImage from "./PlaceHolderImage";

const HeaderImage = ({children}) => (

    <div className={styles.HeaderImage}>
        <div className={styles.image}><PlaceholderImage width="1600" height="1000"/></div>
    </div>

)

export default HeaderImage